@use "global/mixin" as m;
@use "global/variable" as v;

.l-article {
  &_heading {
    text-align: center;
    font-weight: bold;
    font-size: 3.2rem;
    margin-bottom: .5em;
    line-height: 2;
  }
  
  &_date {
    color: #666;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  &_mainImg {
    max-height: 520px;
    max-width: 100%;
    margin: 0 auto 4rem;
    display: block;
  }

  &_cont {
    line-height: 1.5;
    
    h1, h2, h3, h4, h5, h6 {
      margin-top: 1.5em;
      margin-bottom: 0.75em;
      line-height: 1.2;
      font-weight: bold;
    }
  
    h1 {
      font-size: 2.8rem;
    }
  
    h2 {
      font-size: 2.4rem;
    }
  
    h3 {
      font-size: 2.2rem;
    }
  
    h4 {
      font-size: 2.0rem;
    }
  
    h5 {
      font-size: 1.8rem;
    }
  
    h6 {
      font-size: 1.6rem;
    }
  
    p {
      margin-bottom: 1.5em;
    }
  
    a {
      display: inline-block;
      text-decoration: underline;
    }
  
    ol, ul {
      margin-bottom: 1.5em;
      padding-left: 2em;
    }
    
    ol {
      list-style-type: decimal;
    }
    
    ul {
      list-style-type: disc;
    }
  
    li {
      list-style: inherit;
    }
    
    pre {
      background-color: #eee;
      color: #333;
      padding: 1em 1.5em;
      margin-bottom: 1.5em;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    
    blockquote {
      border-left: .5em solid #e4e4e4;
      padding: 1em;
      margin-bottom: 1.5em;
      box-shadow: 0 5px 10px rgba(0,0,0,.05);
      
      p {
        margin-bottom: 0;
      }
      
      cite {
        text-align: right;
        font-style: italic;
        display: block;
        color: #888;
        margin-top: 1.5em;
        
        &::before {
          content: "";
          width: 1.25em;
          height: 2px;
          background-color: #aaa;
          display: inline-block;
          vertical-align: middle;
          margin-right: .5em;
          position: relative;
          top: -1px;
        }
      }
    }
    
    em {
      font-style: italic;
    }
    
    strong {
      font-weight: bold;
    }
  
    img {
      max-width: 100%;
      display: inline-block;
      vertical-align: middle;
      
      + img {
        margin-left: 2rem;
      }
      
      &:not(:only-child) {
        max-width: calc(49% - 1rem);
      }
      
      &.alignleft {
        display: block;
        margin-right: auto;
        margin-left: 0;
      }
  
      &.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
  
      &.alignright {
        display: block;
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
}