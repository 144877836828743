@use "global/mixin" as m;
@use "global/variable" as v;

.c-heading {
  position: relative;
  background-image: linear-gradient(to right, v.$color-blue 20%, v.$color-blueLight 80%);
  color: #fff;

  &_cont {
    padding: 16rem 0;
    width: 70%;

    @include m.sp {
      padding: 3rem 0;
      width: 60%;
    }
  }

  &_subttl {
    @include m.rwd-fz(18);
    margin-bottom: 1em;
    letter-spacing: 0.136em;

    &::before {
      content: "";
      width: 3rem;
      height: 2px;
      background-color: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.5rem;
      position: relative;
      top: -2px;
    }

    &--blue {
      color: v.$color-blue;

      &::before {
        background-color: v.$color-blue;
      }
    }
  }

  &_ttl {
    @include m.rwd-fz(40);
    line-height: 1.2;
    font-weight: 500;
    letter-spacing: .08em;
  }

  &_txt {
    line-height: 2;
    margin-top: 2em;
  }

  &_img {
    width: 30%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;

    @include m.sp {
      width: 40%;
    }
  }
}