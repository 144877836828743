@use "global/mixin" as m;
@use "global/variable" as v;

.c-outline {
  border-radius: 1rem;
  @include m.rwd-spacing(padding, 40);
  background-color: v.$color-blueBg;

  &_ttl {
    @include m.rwd-fz(26);
    font-weight: bold;
    color: v.$color-blue;
  }

  &_list {
    margin-top: 1rem;
  }

  &_item {
  }
  
  &_link {
    line-height: 2;
    text-decoration: underline;
  }
}