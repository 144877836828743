// collapse
.collapsing {
  @media screen and (max-width: 1200px) {
    height: 0;
    overflow: hidden;
    transition: height .5s;
  }
}

.collapse:not(.show) {
  @media screen and (max-width: 1200px) {
    display: none;
  }
}