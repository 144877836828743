@use "global/mixin" as m;
@use "global/variable" as v;

.c-tags {
  margin-bottom: -1rem;
  
  .c-tag {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.c-tag {
  background-color: v.$color-blue;
  border-radius: 3em;
  @include m.rwd-fz(12);
  line-height: 2.5;
  padding: 0 2rem;
  display: inline-block;
  color: #fff;
}