@use "global/mixin" as m;
@use "global/variable" as v;

.l-breadcrumb {
  display: flex;
  align-items: center;
  font-size: 1.4rem;

  @include m.sp {
    flex-wrap: wrap;
  }

  &_item {
    &::after {
      content: '>';
      display: inline-block;
      margin: 0 1em;
    }

    &:last-child{
      &::after {
        display: none;
      }
    }
  }

  &_link {
    color: rgb(34, 79, 156);
    text-decoration: underline;
    display: inline-block;
    
    &:hover {
      text-decoration: underline;
    } 
  }
}