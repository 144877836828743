@use "global/mixin" as m;
@use "global/variable" as v;

.c-tableComplex {
  width: 100%;
  text-align: center;

  &_section {
    margin: 5rem auto;

    @include m.sp {
      margin: 3rem auto;
    }
  }

  &_wrap {
    overflow: auto;
    width: 100%;

    .c-tableComplex {
      width: 1000px;
      // table-layout: fixed;

      @include m.sp {
        width: 800px;
      }
    }
  }

  &_caption {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 3rem;
    text-align: center;

    @include m.sp {
      text-align: left;
      margin-bottom: 1rem;
    }
  }

  &_thead {
    
    .c-tableComplex_tr {
      &:first-child {
        .c-tableComplex_th {
          background-color: #aacce9;
          font-weight: bold;
        }
      }

      &:only-child {
        .c-tableComplex_th {
          background-color: #dceaf6;
          font-weight: bold;
        }
      }
    }
    
    .c-tableComplex_th {
      background-color: #dceaf6;
      font-weight: normal;
      border-right: 1px dashed #fff;

      &:last-child {
        border-right: none;
      }
    }
  }

  &_tbody {
    .c-tableComplex_tr {
      &:last-child {
        .c-tableComplex_td {
          border-bottom: none;
        }
      }
    }

    .c-tableComplex_th {
      height: 2.5em;
      border-top: 1px solid #fff;
    }
  }

  &_th {
    vertical-align: middle;
    background-color: #dceaf6;
    padding: 0 .25em;

    &:last-child {
      border-right: none;
    }
  }

  &_td {
    background-color: #fff;
    border-left: 1px dashed #dceaf6;
    border-right: 1px dashed #dceaf6;
    border-top: 1px solid #dceaf6;
    border-bottom: 1px solid #dceaf6;
    vertical-align: middle;
    padding: .75em;

    &:last-child {
      border-right: none;
    }
  }

  &_sticky {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  .c-tableComplex_divider {
    &Th {
      border-right: 4px solid #fff;
    }

    &Td {
      border-right: 4px solid #dceaf6;
    }
  }
}