@use "global/mixin" as m;
@use "global/variable" as v;

.l-sidebar {
  max-width: 300px;
  
  &_box {
    background-color: beige;
    padding: 3rem;
    color: brown;
    margin-bottom: 2rem;

    &_ttl {
      font-weight: 700;
      padding-bottom: 0.5em;
      border-bottom: 1px solid brown;
      margin-bottom: 2rem;
      font-size: 1.8rem;
    }

    &_list {

    }

    &_item {
      font-weight: 500;

      + * {
        margin-top: 1em;
      }
    }

    &_link {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > svg {
        width: 18px;
        height: 10px;
      }

      &:hover {
        background: linear-gradient(to top, brown 1px, transparent 1px);
      }
    }
  }
}