@use "global/mixin" as m;
@use "global/variable" as v;

.c-indexSection {
  line-height: 2;

  &_row {
    display: flex;
    justify-content: space-between;

    > :first-child {
      width: 50%;
    }

    > :last-child {
      width: 41.6%;
    }
  }

  &_ttl {
    margin-bottom: 0.75em;
    line-height: 1.75;
  }

  &_subttl {
    @include m.rwd-spacing(margin-top, 80);
  }

  &_lead {
    @include m.rwd-fz(22);
    @include m.rwd-spacing(margin-bottom, 60);
  }

  &_txt {
    @include m.rwd-fz(15);
  }

  &_img {
    @include m.rwd-spacing(margin-top, 40);
    width: 100%;
    transform: scale(1.1);
    transform-origin: left top;
  }

  &--style01 {
    background-image: linear-gradient(to right, v.$color-blue, v.$color-blueLight);
    background-size: 47.5% 60rem;
    background-repeat: no-repeat;
    margin: 16rem 0;
    color: #fff;

    .c-indexSection_subttl {
      &::before {
        background-color: #fff;
      }
    }
  }

  &--style02 {
    padding: 10rem 0;
    background-color: v.$color-blueBg;

    .c-indexSection_subttl {
      color: v.$color-blue;

      &::before {
        background-color: v.$color-blue;
      }
    }

    .c-indexSection_ttl {
      color: v.$color-dark;
    }

    .c-indexSection_lead {
      color: v.$color-blue;
    }

    .c-indexSection_txt {
      color: v.$color-dark;
    }
  }

  &--style03 {
    padding: 9rem 0;
    background-image: url(../img/common/bg_lightblue.jpg);
    background-size: cover;
    background-position: 50% 50%;

    .c-indexSection_subttl {
      @include m.rwd-spacing(margin-top, 0);
    }

    .c-indexSection_ttl {
      color: v.$color-dark;
      margin-bottom: 0.25em;
    }

  }
}