@use "global/mixin" as m;
@use "global/variable" as v;

.c-btn {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  width: 46rem;
  max-width: 100%;
  height: 7rem;
  color: #fff;
  padding: 0 2rem;
  @include m.rwd-fz(18);
  border-radius: 4rem;
  background: linear-gradient(to right, v.$color-blue, v.$color-blueLight);
  cursor: pointer;
  transition: all .3s;
  z-index: 1;

  @include m.sp {
    height: 5rem;
    font-size: 1.4rem;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    border: 2px solid v.$color-blue;
    background-color: #fff;
    width: 100%;
    height: 100%;
    transition: all .3s;
    border-radius: inherit;
    z-index: -1;
  }
  
  &:hover {
    color: v.$color-blue;

    * {
      stroke: v.$color-blue;
      fill: v.$color-blue;
    }

    &::before {
      opacity: 1;
    }
  }

  &--outline {
    color: v.$color-blue;

    * {
      stroke: v.$color-blue;
      fill: v.$color-blue;
    }

    &::before {
      opacity: 1;
      border: none;
    }

    &:hover {
      color: #fff;

      * {
        stroke: #fff;
        fill: #fff;
      }

      &::before {
        opacity: 0;
      }
    }
  }

  &--sm {
    height: 3rem;
    padding: 0 2rem;
    font-size: 1.4rem;
  }

  &--md {
    height: 4rem;
    padding: 0 4rem;
    font-size: 1.6rem;
  }

  &--lg {
    height: 6rem;
    padding: 0 6rem;
    font-size: 2rem;
  }

  &--icon {
    .c-btn_icon {
      position: absolute;
      right: 3rem;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 9px;
      transition: all .3s;

      @include m.sp {
        width: 18px;
        height: 5px;
        right: 2rem;
      }
    }
  }
}


