@use "global/mixin" as m;
@use "global/variable" as v;

.c-subttl {
  @include m.rwd-fz(18);
  margin-bottom: 1em;
  letter-spacing: 0.136em;

  &::before {
    content: "";
    width: 3rem;
    height: 2px;
    background-color: v.$color-dark;
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5rem;
    position: relative;
    top: -2px;
  }

  &--white {
    color: #fff;

    &::before {
      background-color: #fff;
    }
  }

  &--blue {
    color: v.$color-blue;

    &::before {
      background-color: v.$color-blue;
    }
  }
}