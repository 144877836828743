@use "global/mixin" as m;
@use "global/variable" as v;

.c-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @include m.rwd-spacing(gap, 30);

  @include m.sp {
    grid-template-columns: 1fr;
  }
}

.c-column {
  background-color: v.$color-blueBg;
  padding: 5rem 3rem;

  @include m.sp {
    padding: 2rem;
  }

  &_ttl {
    @include m.rwd-fz(26);
    @include m.rwd-spacing(margin-bottom, 30);
    font-weight: bold;
    color: v.$color-blue;
    text-align: center;
    height: 3.6em;
    line-height: 1.8;
  }

  &_txt {
    @include m.rwd-spacing(margin-bottom, 30);
  }
  
  &_btn {
    width: 100%;
  }
}