@use "global/mixin" as m;
@use "global/variable" as v;

.c-link {
  text-decoration: underline;
  display: inline-block;

  &_icon {
    display: inline-block;
    margin-left: 1rem;
  }
}