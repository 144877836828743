@use "global/mixin" as m;
@use "global/variable" as v;

.u-ta {
  &-l {
    text-align: left;
  }

  &-c {
    text-align: center;
  }

  &-r {
    text-align: right;
  }

  &-sp {
    &-l {
      @include m.sp {
        text-align: left;
      }
    }
    &-c {
      @include m.sp {
        text-align: center;
      }
    }
    &-r {
      @include m.sp {
        text-align: right;
      }
    }
  }
}