@use "global/mixin" as m;
@use "global/variable" as v;

.c-breadcrumb {
  @include m.rwd-fz(12);
  color: v.$color-blue;
  font-weight: 500;

  &_item {
    display: inline-block;
    margin-left: 0.5em;
    vertical-align: middle;

    &:not(:first-child) {
      &::before {
        content: ">";
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.5em;
        position: relative;
        top: -2px;
      }
    }

    &:last-child {
      color: v.$color-dark;
    }
  }

  &_link {
    display: inline-block;
    
    &:hover {
      text-decoration: underline;
    }
  }
}