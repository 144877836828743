@use "global/mixin" as m;
@use "global/variable" as v;

.c-note {
  padding-left: 1.5em;
  text-indent: -1.5em;

  & + .c-note {
    margin-top: 1rem;
  }

  &::before {
    content: "※";
    margin-right: .5em;
  }
}