.u-lh {
  &-1 {
    line-height: 1 !important;
  }

  &-1\.25 {
    line-height: 1.25 !important;
  }

  &-1\.5 {
    line-height: 1.5 !important;
  }

  &-1\.75 {
    line-height: 1.75 !important;
  }

  &-2 {
    line-height: 2 !important;
  }

  &-2\.5 {
    line-height: 2.5 !important;
  }

  &-3 {
    line-height: 3 !important;
  }
}