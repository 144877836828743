@use "global/mixin" as m;
@use "global/variable" as v;

$gap: 6rem;

.c-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: $gap;
  
  @include m.sp {
    gap: $gap * 0.5;
  }
  
  &_col {

  }

  &--common {
    flex-wrap: nowrap;
    justify-content: space-between;

    @include m.sp {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &--2 {
    .c-row_col {
      width: calc((100% - $gap) * 0.5);
      
      @include m.sp {
        width: 100%;
      }
    }
  }

  &--3 {
    .c-row_col {
      width: calc((100% - $gap * 2) * 0.333333);
      
      @include m.sp {
        width: 100%;
      }
    }
  }

  &--4 {
    .c-row_col {
      width: calc((100% - $gap * 3) * 0.25);

      @include m.sp {
        width: calc((100% - $gap * 0.5) * 0.5);
      }
    }
  }
}