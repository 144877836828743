@use "global/mixin" as m;
@use "global/variable" as v;

.c-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @include m.rwd-spacing(gap, 30);

  @include m.sp {
    grid-template-columns: 1fr;
  }
}

.c-card {
  box-shadow: v.$box-shadow;
  background-color: #fff;

  &_img {
    height: 0;
    padding-bottom: 68.5%;
    position: relative;
    
    img {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_cont {
    padding: 4rem 3rem;

    @include m.sp {
      padding: 2rem;
    }
  }

  &_date {
    margin-bottom: 2rem;
  }

  &_cat {
    margin-bottom: 2rem;
    color: v.$color-blue;
  }

  &_ttl {
    @include m.rwd-fz(18);
    margin-bottom: 2rem;
    font-weight: bold;
  }

  &_txt {
    @include m.rwd-spacing(margin-bottom, 30);

  }
  
  &_tags {

  }
}