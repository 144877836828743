@use "global/mixin" as m;
@use "global/variable" as v;

.c-newsList {
  &_item {
    border-top: 1px solid v.$color-gray;
    
    &:last-child {
      border-bottom: 1px solid v.$color-gray;
    }
  }

  &_link {
    display: flex;
    align-items: center;
    position: relative;
    padding: 2em 0;
    transition: all .3s;

    @include m.sp {
      padding: 1.5em 1.5em 1.5em .5em;
    }

    &:hover {
      background-color: #F5F2ED;
      opacity: 1;
    }
  }

  &_tag {
    @include m.rwd-spacing(margin-right, 30);
  }

  &_date {
    font-weight: 400;
    @include m.rwd-spacing(margin-right, 40);
  }

  &_ttl {
    font-weight: 500;
  }
}