.u-d {
  &-b {
    display: block !important;
  }

  &-ib {
    display: inline-block !important;
  }

  &-f {
    display: flex !important;
  }

  &-if {
    display: inline-flex !important;
  }

  &-i {
    display: inline !important;
  }
}