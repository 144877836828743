@use 'sass:math';

@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tab {
  @media screen and (min-width: 768px) and (max-width: 900px) {
    @content;
  }
}

@mixin tab-sp {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}


/* ---------- rwd ---------- */
@mixin rwd-fz($val, $important: null) {
  
  @if ($important == 'important') {
    $important: !important;
  }

  font-size: $val*0.1rem $important;
  
  @include sp {
    @if ( $val == 12) {
      font-size: 1.0rem $important;
    } @else if ( $val == 14 ) {
      font-size: 1.2rem $important;
    } @else if ( $val == 16 ) {
      font-size: 1.4rem $important;
    } @else if ( $val == 18 ) {
      font-size: 1.6rem $important;
    } @else if ( 20 <= $val and $val <= 22 ) {
      font-size: 1.8rem $important;
    } @else if ( 24 <= $val and $val <= 30 ) {
      font-size: 2.0rem $important;
    } @else if ( 32 <= $val and $val <= 42 ) {
      font-size: 2.4rem $important;
    } @else if ( 44 <= $val and $val <= 48 ) {
      font-size: 3.0rem $important;
    } @else if ( 52 <= $val and $val <= 56 ) {
      font-size: 3.2rem $important;
    } @else if ( $val == 60 ) {
      font-size: 3.6rem $important;
    } @else if ( $val == 72 ) {
      font-size: 4.0rem $important;
    } @else if ( $val == 84 ) {
      font-size: 4.8rem $important;
    }
  }
  
}

@mixin rwd-spacing-ratio($property, $value, $ratio: 0.5) {
  #{$property}: #{$value * 0.1}rem;

  @include sp {
    #{$property}: #{$value * 0.1 * $ratio}rem;
  }
}

@mixin rwd-spacing($property, $val) {

  @if ( $val == 0 ) {
    #{$property}: 0;
  } @else {
    #{$property}: #{$val * 0.1}rem;

    @include sp {
      @if ( $val <= 10 ) {
        #{$property}: 1rem;
      } @else if ( $val <= 40 ) {
        #{$property}: 2rem;
      } @else if ( $val <= 60 ) {
        #{$property}: 3rem;
      } @else if ( $val <= 100 ) {
        #{$property}: 4rem;
      } @else if ( $val > 100 ) {
        #{$property}: 6rem;
      }
    }
  }

}



/* ---------- shortcut ---------- */
@mixin bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@mixin pos-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin pos-full {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin hover-opacity ($anime: $anime) {
  opacity: 1;
  transition: opacity #{$anime};

  &:hover {
    opacity: 0.8;
  }
}

@mixin hover-opacity-pseudo($anime: .3s) {
  position: relative;
  
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    transition: opacity #{$anime}; 
  }
  
  &:hover {
    &::after {
      opacity: 0.3;
    }
  }
}

@mixin hover-underline {
  display: inline-block;
  background-size: 0 1px;
  background-position: 0 100%;
  background-image: linear-gradient(#000, #000);
  background-repeat: no-repeat;
  @include hover;

  &:hover {
    background-size: 100% 1px;
  }
}

@mixin color-gradient($dir, $color1, $color2) {
  width: fit-content;
  background: $linear-gradient(to #{$dir}, #{$color1}, #{$color2});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}