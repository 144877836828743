.u-pos {
  &-r {
    position: relative;
  }

  &-a {
    position: absolute;
  }

  &-s {
    position: static;
  }
}