@use "global/mixin" as m;
@use "global/variable" as v;

$font-sizes: (
  10: 1.0rem,
  12: 1.2rem,
  14: 1.4rem,
  16: 1.6rem,
  18: 1.8rem,
  20: 2.0rem,
  24: 2.4rem,
  28: 2.8rem,
  30: 3.0rem,
  32: 3.2rem,
  36: 3.6rem,
  40: 4.0rem,
  48: 4.8rem,
  50: 5.0rem,
  60: 6.0rem,
);

@each $fontSize, $fontSize-value in $font-sizes {
  .u-fz-#{$fontSize} {
    font-size: #{$fontSize-value} !important;
  }
}

@each $fontSize, $fontSize-value in $font-sizes {
  .u-fz-sp-#{$fontSize} {
    @include m.sp {
      font-size: #{$fontSize-value} !important;
    }
  }
}

// @each $fontSize, $fontSize-value in $font-sizes {
//   .u-fz-rwd-#{$fontSize} {
//     @include m.rwd-fz($fontSize, 'important');
//   }
// }

// Use RFS
// @each $fontSize, $fontSize-value in $font-sizes {
//   .u-fz-rfs-#{$fontSize} {
//     @include rfs($fontSize-value, font-size);
//   }
// }