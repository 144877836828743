@use "global/mixin" as m;
@use "global/variable" as v;

.p-index {

  &_intro {
    @include m.rwd-spacing(margin-top, 120);

    &_ttl {
      @include m.rwd-fz(26);
      color: v.$color-blue;
      text-align: center;
      font-weight: bold;
      margin-bottom: 2em;
    }

    &_box {
      @include m.rwd-spacing(margin-top, 50);
      background-color: v.$color-blueBg;
      padding: 5rem 6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include m.sp {
        padding: 2rem;
      }

      &Ttl {
        flex-shrink: 0;
        color: v.$color-blue;
        @include m.rwd-fz(26);
      }
      
      &Txt {
        @include m.rwd-fz(18);
        border-left: 2px solid v.$color-blue;
        margin-left: 3em;
        padding-left: 3em;
      }
    }
  }

  &_slider {
    .swiper {
      height: 12rem;
      position: relative;
    
      @include m.sp {
      }
    }

    .swiper-wrapper {
      transition-timing-function: linear;
    }
    
    .swiper-slide {
      width: 24rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    
      @include m.sp {
        max-width: 15rem;
      }
    }
  }

  &_function {
    &_list {

    }

    &_item {
      display: flex;
      align-items: center;
      @include m.rwd-spacing(gap, 30);
      
      
      & + * {
        margin-top: 2em;
      }
    }

    &_icon {
      flex-shrink: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      
      img {
        max-width: 100%;
        max-height: 4rem;
      }
    }

    &_txt {
      @include m.rwd-fz(22);
      color: v.$color-blue;

      &--sm {
        @include m.rwd-fz(18);
      }
    }

    &_btn.c-btn {
      @include m.rwd-spacing(margin-top, 90);
      margin-left: 0;
    }
  }

  &_case {
    &_btn.c-btn {
      @include m.rwd-spacing(margin-top, 80);
    }
  }

  &_information {
    padding: 12rem 0;
    
    &_row {
      display: flex;
      gap: 7.5%;

      > :first-child {
        flex-shrink: 0;
      }

      > :last-child {
        flex-grow: 1;
      }
    }

    &_col {

    }

    &_btn.c-btn {
      @include m.rwd-spacing(margin-top, 80);
    }
  }
}

