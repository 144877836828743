@use "global/mixin" as m;
@use "global/variable" as v;

.c-ttl {
  position: relative;
  @include m.rwd-fz(40);
  letter-spacing: 0.08em;
  font-weight: 500;
  
  &--border {
    @include m.rwd-spacing(padding-bottom, 30);
    border-bottom: 1px solid v.$color-dark;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 5rem;
      height: 2px;
      background-color: v.$color-blue;
    }
  }
  
  &--m {
    @include m.rwd-fz(26);
    color: v.$color-blue;
    font-weight: 700;
    letter-spacing: 0.06em;
  }

  &--s {
    @include m.rwd-fz(22);
    letter-spacing: 0.06em;
  }


  &--parts {
    background-color: v.$color-gray;
    font-weight: bold;
    @include m.rwd-fz(20);
    line-height: 1.8;
    padding-left: 1em;
    margin-bottom: 3em;
    margin-top: 4em;
  }
}