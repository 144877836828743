@use "global/mixin" as m;
@use "global/variable" as v;

$color--black: #000000;
$color--light-grey: #E6E6E6;
$color--grey: #CCCCCC;
$color--dark-grey: #7B7B7B;

.c-select {
	position: relative;
	display: inline-block;
	margin-bottom: 15px;
	width: 200px;
  margin: auto;
	select {
		display: inline-block;
		width: 100%;
		cursor: pointer;
		padding: 10px 15px;
		outline: 0;
		border: 0;
		border-radius: 0;
		background: $color--light-grey;
		color: $color--dark-grey;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		&::-ms-expand {
			display: none;
		}
		&:disabled {
			opacity: 0.5;
			pointer-events: none;
			& ~ .c-select__arrow {
				border-top-color: $color--grey;
			}
		}
	}
}
.c-select select:hover,
.c-select select:focus {
	color: $color--black;
	background: $color--grey;
}
.c-select__arrow {
	position: absolute;
	top: 16px;
	right: 15px;
	width: 0;
	height: 0;
	pointer-events: none;
	border-style: solid;
	border-width: 8px 5px 0 5px;
	border-color: $color--dark-grey transparent transparent transparent;
}
.c-select select:hover ~ .c-select__arrow,
.c-select select:focus ~ .c-select__arrow {
	border-top-color: $color--black;
}
