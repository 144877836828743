@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-c {
  &-black {
    color: v.$color-black;
  }

  &-white {
    color: v.$color-white;
  }
}