@use "global/mixin" as m;
@use "global/variable" as v;

.c-tagBox {
  background-color: #fff;
  padding: 4rem 5rem;
  box-shadow: v.$box-shadow;

  @include m.sp {
    padding: 2rem;
  }

  &_ttl {
    @include m.rwd-fz(18);
    margin-bottom: 1em;
  }
  
}