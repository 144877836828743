@use "global/mixin" as m;
@use "global/variable" as v;


// header
.l-header {
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;

  @include m.sp {
    display: flex;
    flex-direction: column;
  }

  & + * {
    margin-top: 80px;

    @include m.sp {
      margin-top: 65px;
    }
  }
  
  &_inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 3rem;
    height: 80px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .3);

    @include m.sp {
      height: 65px;
    }
    
    @include m.sp {
      padding: 0 0 0 1.5rem;
    }
  }

  &_logo {
    display: flex;
    align-items: center;
    gap: 2rem;
    font-size: 2.6rem;

    @include m.sp {
      gap: 1rem;
      font-size: 1.2rem;
    }

    img {
      @include m.sp {
        width: 8rem;
      }
    }
  }

  &_nav {
    margin-right: 6rem;

    @include m.tab-sp {
      display: none;
    }

    margin-left: auto;

    @include m.sp {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #000;
      margin-top: 65px;
    }
    
    &_list {
      display: flex;
      height: 100%;
      
      @include m.sp {
        flex-direction: column;
        height: 100vh;
      }
    }

    &_item {
      position: relative;

      + * {
        margin-left: 3.5vw;
      }
      
      @include m.sp {
        margin-left: 0;
      }

      &:hover {
        .l-header_nav_child {
          z-index: 1;
          opacity: 1;
          height: auto;
        }
      }
      
      &--hasChild {
        .l-header_nav_link {
          &::after {
            content: '';
            display: inline-block;
            align-items: center;
            margin-left: 1rem;
            transform: translateY(-25%) rotate(45deg);
            width: 1rem;
            height: 1rem;
            border-right: 2px solid #000;
            border-bottom: 2px solid #000;

            @include m.sp {
              display: none;
            }
          }
        }
      }

      &--point {
        margin-left: 0;

        @include m.tab-sp {
          margin-left: auto;
        }

        .l-header_nav_link {
          color: #fff;
          width: 18rem;
          text-align: center;
          padding: 0 1rem;

          @include m.sp {
            width: auto;
            font-size: 1.2rem;
          }
        }
      }

      &--sky {
        .l-header_nav_link {
          background-color: #1566ad;
        }
      }

      &--blue {
        .l-header_nav_link {
          background-color: #2d308e;
        }
      }

      &--pink {
        .l-header_nav_link {
          background-color: #b7315e;
        }
      }
    }

    &_link {
      height: 100%;
      cursor: pointer;
      @include m.flex-center;
      text-decoration: none;
      color: #000;
      white-space: nowrap;
      transition: all .2s;
    }

    &_child {
      
      @include m.pc {
        position: absolute;
        left: 0;
        z-index: -1;
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: opacity .3s;
      }
      
      &Item {
        
      }

      &Link {
        height: 100%;
        background-color: #2d308e;
        color: #fff;
        font-size: 1.4rem;
        padding: 1em 2rem;
        white-space: nowrap;
        font-weight: 500;
        min-width: 15rem;

        &--sm {
          padding: .5em 2rem;
        }

        &--outer {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &::after {
            content: '';
            width: 1rem;
            height: 1rem;
            border: 1px solid #fff;
            box-shadow: -2px 2px 0 #2d308e, -3px 3px 0 #fff;
            position: relative;
            top: -2px;
          }

          &[href]:hover {
            &::after {
              box-shadow: -2px 2px 0 #6668ad, -3px 3px 0 #fff;
            }
          }
        }

        &[href]:hover {
          background-color: #6668ad;
          opacity: 1;
        }
      }
    }
  }

  &_navSp {

    display: none;
    
    @include m.tab-sp {
      display: block;
    }

    background-color: #000;
    height: 100vh;
    overflow: scroll;
    padding-bottom: 25vh; 
    
    &_list {
    }


    &_item {
    }

    &_link {
      background-color: #2d308e;
      color: #fff;
      line-height: 3.75;
      padding-left: 2em;
    }

    &_child {
      &Item {
        & + * {
          border-top: 1px solid #fff;
        }
      }

      &Link {
        cursor: pointer;
        background-color: #d1d1e6;
        color: #2d308e;
        line-height: 3.75;
        padding: 0 2em;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
          content: '';
          width: .8rem;
          height: .8rem;
          border-right: 2px solid #2d308e;
          border-bottom: 2px solid #2d308e;
          transform: rotate(-45deg);
        }

        &--outer {
          &::after {
            content: '';
            width: .8rem;
            height: .8rem;
            border: 1px solid #2d308e;
            transform: translateY(-2px);
            box-shadow: -2px 2px 0 #d1d1e6,
            -3px 3px 0 #2d308e;
          }
        }
      }

      .l-header_navSp_child {
        .l-header_navSp_childItem {
          & + * {
            border-top: 1px solid #d1d1e6;
          }
        }  
        .l-header_navSp_childLink {
          background-color: #f2f2f8;
        }
      }
    }

    &_check {
      position: absolute;
      left: -100rem;
      top: -100rem;
      z-index: -100;
      width: 0;
      height: 0;
      opacity: 0;
      display: none;
      overflow: hidden;

      ~ .l-header_navSp_child {
        display: none;
      }

      &:checked {
        ~ .l-header_navSp_childLink {
          &::after {
            transform: rotate(45deg);
          }
        }

        ~ .l-header_navSp_child {
          display: block;
        }
      }
    }
  }

  .c-hamburger {
    display: none;

    @include m.tab-sp {
      display: flex;
    }
  }
}