@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-bgc {
  &-black {
    background-color: v.$color-black !important;
  }

  &-white {
    background-color: v.$color-white !important;
  }
}