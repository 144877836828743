@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-ff {
  &-yugo {
    font-family: v.$yugo !important;
  }

  &-yumin {
    font-family: v.$yumin !important;
  }

  &-noto {
    font-family: v.$noto !important;
  }

  &-notoSerif {
    font-family: v.$notoSerif !important;
  }

  &-lato {
    font-family: v.$lato !important;
  }
}