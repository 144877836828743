@use "global/mixin" as m;
@use "global/variable" as v;

.l-scrollBtn {
  position: fixed;
  right: 5%;
  bottom: 5%;
  background-color: gray;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  @include m.flex-center;
  // display: none;

  @include m.sp {
    width: 4rem;
    height: 4rem;
  }

  &::after {
    content: '';
    width: 15px;
    height: 15px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: translateY(25%) rotate(-135deg);

    @include m.sp {
      width: 10px;
      height: 10px;
    }
  }
}