@charset "UTF-8";

// Transition //
$anime: 0.3s ease;

// Color //
$color-black: #000;
$color-dark: #373737;
$color-gray: #D8D8D8;
$color-grayDark: #707070;
$color-white: #fff;
$color-blue: #00468C;
$color-blueLight: #2F88E3;
$color-blueLight02: #2871C7;
$color-blueBg: #EAF3FC;

// Font //
// Noto
$noto: 'Noto Sans JP', sans-serif;
$notoSerif: 'Noto Serif JP', serif;

// 游ゴ・游明
$yumin: 游明朝, 'Yu Mincho', YuMincho, メイリオ, Meiryo, serif;
$yugo: '游ゴシック体', YuGothic, "游ゴシック", "Yu Gothic", 'メイリオ', sans-serif;

// Google Fonts
$lato: 'Lato', sans-serif;



$box-shadow: 0 3px 6px rgba($color-dark, .1)