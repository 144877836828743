@use "global/mixin" as m;
@use "global/variable" as v;

.l-footer {
  padding: 3rem 0;

  @include m.sp {
    padding: 4rem 0 2rem;
  }

  &_copyright {
    margin-top: 3rem;
    font-size: 1.2rem;
    text-align: center;

    @include m.sp {
      margin-top: 2rem;
    }
  }

  &_cont {
  }

  &_row {
    display: flex;
    gap: 6rem;

    @include m.tab {
      justify-content: center;
    }

    @include m.sp {
      flex-direction: column;
      gap: 3rem;
    }
  }

  &_nav {
    &_row {
      display: flex;
      gap: 4rem;

      @include m.sp {
        flex-wrap: wrap;
        gap: 3rem 2rem;
      }
    }

    &_col {
      @include m.sp {
        width: calc(50% - 1rem);
      }
    }

    &_item {
      & + * {
        margin-top: 1rem;
      }
  
      a {
        display: inline-block;
      }
    }

    &_main {
      font-weight: bold;
    }

    &_sub {
      font-weight: normal;

      > * {
        margin-top: 1rem;
      }
    }
  }

  &_subNav {
    margin-top: 3rem;

    &_inner {
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 2rem 0;
    }

    &_list {
      display: flex;
      justify-content: space-between;

      @include m.sp {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }
    }

    &_item {

    }
  }
}