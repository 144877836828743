@use "global/mixin" as m;
@use "global/variable" as v;

.l-mv {
  position: relative;
  height: 60rem;
  @include m.bg;
  background-image: url(../img/index/mv.jpg);
  background-size: cover;
  background-position: 50% 50%;
  overflow: hidden;

  @include m.sp {
  }

  &_wrap {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    @include m.sp {
      align-items: flex-start;
    }
  }

  &_cloud {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 63rem;
    mix-blend-mode: screen;

    @include m.sp {
      width: 100%;
      right: -25%;
      top: unset;
      bottom: 9rem;
      transform: scale(1.25);

    }
  }

  &_cont {
    @include m.rwd-spacing(margin-bottom, 60);

    @include m.sp {
      width: 80%;
      margin-top: 5rem;
    }
  }

  &_ttl {
    position: relative;
    z-index: 1;   

    &_line {
      line-height: 1.75;
      color: #fff;
      @include m.rwd-fz(32);
      background-image: linear-gradient(to right, v.$color-blue, v.$color-blueLight);
      display: block;
      width: fit-content;
      margin-top: 1rem;
      padding: 0 2rem 0 1rem;
      letter-spacing: 0.12em;
      font-weight: 500;

      @include m.sp {
        font-size: 2.2rem;
      }
    }
  }

  &_btn.c-btn {
    @include m.rwd-spacing(margin-top, 50);
    margin-left: 0;

    @include m.sp {
      width: 20rem;
      padding-left: 0;
    }
  }

  &_subttl {
    font-size: 3rem;

    @include m.sp {
      font-size: 1.6rem;
    }
  }
}