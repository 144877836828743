@use "global/mixin" as m;
@use "global/variable" as v;

.c-mousey {
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  @include m.sp {
    bottom: 2rem;
  }

  &_txt {
    color: #fff;
    letter-spacing: 0.136em;
    @include m.rwd-fz(12);
    margin-bottom: 1rem;
    display: inline-block;
  }

  &_frame {
    width: 30px;
    padding: 10px;
    height: 50px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    margin: auto;
  }

  &_scroller {
    width: 2px;
    height: 10px;
    border-radius: 25%;
    margin: auto;
    background-color: #fff;
    animation-name: mouseyScroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
  }

  @keyframes mouseyScroll {
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px); opacity: 0;}
  }
}