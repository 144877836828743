@use "global/mixin" as m;
@use "global/variable" as v;

.c-content {

  &_ttl {
    @include m.rwd-fz(26);
    color: v.$color-blue;
    @include m.rwd-spacing(margin-bottom, 30);
    border-left: 8px solid v.$color-blue;
    padding-left: 0.6em;
  }

  &_img {
    @include m.rwd-spacing(margin-bottom, 40);
  }

  &_txt {

  }

  &_row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include m.rwd-spacing(gap, 40);
    
    @include m.sp {
      grid-template-columns: 1fr;
    }

    > * {
      margin-bottom: 0;

      @include m.sp {
        margin-bottom: 0;
      }
    }

    &--reverse {
      > :last-child {
        order: -1;
      }
    }

    &--vertical {
      grid-template-columns: 1fr;
    }

    &--verticalReverse {
      grid-template-columns: 1fr;

      > :last-child {
        order: -1;
      }
    }
  }
}