@use "global/mixin" as m;
@use "global/variable" as v;

.tab {
  display: none !important;

  @include m.tab {
    display: block !important;
  }
}

.pc {
  @include m.pc {
    display: block !important;
  }

  @include m.sp {
    display: none !important;
  }
}

.sp {
  @include m.pc {
    display: none !important;
  }

  @include m.sp {
    display: block !important;
  }
}


*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
  
  @include m.sp {
    scroll-padding-top: 65px;

    // 320pxで閲覧するとき、フォントサイズがremの設定値に一致する
    // font-size: calc(100vw / 32);

    // 375pxで閲覧するとき、フォントサイズがremの設定値に一致する
    font-size: calc(100vw / 37.5);

    // 375pxで閲覧するとき、フォントのサイズがPC版の
    // 0.8倍
    // font-size: calc(100vw / (37.5 * 1.25));
    
    // 0.75倍
    // font-size: calc(100vw / (37.5 * 4 / 3));

    // 0.6倍
    // font-size: calc(100vw / (37.5 * 5 / 3));
  }

  // @media screen and (max-width: 374px) {
  //   font-size: calc(62.5% * 0.85);
  // }
}

body {
  font-family: v.$noto;
  font-size: 1.5rem;
  line-height: 1.5;
  color: v.$color-dark;
  font-weight: 500;
  letter-spacing: 0.06em;

  @include m.sp {
    font-size: 1.4rem;
  }

  &.is-active {
    overflow: hidden;
  }
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: 'メイリオ', Meiryo;
  }
}

a {
  text-decoration: none;
  color: inherit;
  display: block;
  cursor: pointer;
  transition: opacity v.$anime;
  -webkit-tap-highlight-color: transparent;
}

button {
  -webkit-tap-highlight-color: transparent;
  background-color: unset;
  padding: 0;
  border: none;
  font-size: inherit;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: auto;
}

sup {
  vertical-align: super;
  font-size: inherit;
}

main { // for IE11
  display: block;
}

ruby > rt {
  display: block;
  font-size: 50%;
  text-align: initial;
}

.is-hide {
  display: none;
  position: fixed;
  left: -10000px;
  top: -10000px;
  text-indent: -1000px;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  transform: scale(0);
  z-index: -100000;
}