// justify-content
.u-jc {
  &-c {
    justify-content: center !important;
  }

  &-fs {
    justify-content: flex-start !important;
  }

  &-fe {
    justify-content: flex-end !important;
  }

  &-sa {
    justify-content: space-around !important;
  }

  &-sb {
    justify-content: space-between !important;
  }
}

// align-items
.u-ai {
  &-c {
    align-items: center !important;
  }

  &-s {
    align-items: stretch !important;
  }

  &-fs {
    align-items: flex-start !important;
  }

  &-fe {
    align-items: flex-end !important;
  }
}

// flex-shrink
.u-fxsh {
  &-0 {
    flex-shrink: 0 !important;
  }
}

// flex-wrap
.u-fxw {
  &-n {
    flex-wrap: nowrap !important;
  }

  &-w {
    flex-wrap: wrap;
  }
}